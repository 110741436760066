import React from "react";
import { useNavigate } from "react-router-dom";
import PATH from "Path";
import styled from "styled-components";
import Img01 from "assets/setting/img-01.jpg";
import Img02 from "assets/setting/img-02.jpg";
import Img03 from "assets/setting/img-03.jpg";
import Img04 from "assets/setting/img-04.jpg";
import Img05 from "assets/setting/img-05.jpg";
import Img06 from "assets/setting/img-06.jpg";
import Img07 from "assets/setting/img-07.jpg";
import Img08 from "assets/setting/img-08.jpg";

function SettingIndexPage() {
  const navigate = useNavigate();

  return (
    <Container>
      <div className="wrap">
        <img
          src={Img01}
          onClick={() => navigate(`${PATH.setting_detail}?option=userInfo`)}
          alt=""
        />
        <img
          src={Img02}
          onClick={() => navigate(`${PATH.setting_detail}?option=facilityInfo`)}
          alt=""
        />
      </div>
      <div className="wrap">
        <img
          src={Img03}
          onClick={() => navigate(`${PATH.setting_detail}?option=faceId`)}
          alt=""
        />
        <img
          src={Img04}
          onClick={() => navigate(`${PATH.setting_detail}?option=notification`)}
          alt=""
        />
      </div>
      <div className="wrap">
        <img src={Img05} alt="" />
      </div>
      <div className="wrap">
        <img
          src={Img06}
          onClick={() => navigate(`${PATH.setting_detail}?option=CS`)}
          alt=""
        />
      </div>
      <div className="wrap">
        <img src={Img07} alt="" />
      </div>
      <div className="wrap">
        <img src={Img08} alt="" />
      </div>
    </Container>
  );
}

export default SettingIndexPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;

  .wrap {
    width: 100%;
    border-radius: 26px;
    overflow: hidden;
  }

  img {
    display: block;
    width: 100%;
  }
`;

import styled from "styled-components";
import TopNavigation from "components/TobNavigation";
import Img01 from "assets/info/img-01.jpg";

function InfoPage() {
  return (
    <Container>
      <TopNavigation label="지도 및 시설 안내" />
      <Content>
        <img src={Img01} alt="" />
      </Content>
    </Container>
  );
}

export default InfoPage;

const Container = styled.div`
  position: relative;
  margin-top: auto;
  width: 100%;
  height: 100dvh;
  padding-top: var(--top-nav-height);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  border-radius: 26px;
  overflow: hidden scroll;

  img {
    display: block;
    width: 100%;
    height: fit-content;
    object-fit: cover;
  }
`;

import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import styled from "styled-components";
import TopNavigation from "components/TobNavigation";

function CommunityPage() {
  const { pathname } = useLocation();
  const [pageName, setPageName] = useState<string>("");
  useEffect(() => {
    const splitUrl = pathname.split("/");
    setPageName(splitUrl[splitUrl.length - 1]);
  }, [pathname]);

  const renderNavigation = () => {
    if (pageName === "notice_detail") {
      return <TopNavigation label="" backType={-1} more />;
    }

    if (pageName === "feed_detail") {
      return <TopNavigation label="" backType={-1} more share />;
    }

    if (pageName === "feed_write") {
      return <TopNavigation label="글 작성하기" backType={-1} />;
    }

    return <TopNavigation label="커뮤니티" backType={".."} />;
  };
  return (
    <Container>
      {renderNavigation()}
      <Outlet />
    </Container>
  );
}

export default CommunityPage;

const Container = styled.div`
  margin-top: auto;
  width: 100%;
  height: 100dvh;
  padding-top: var(--top-nav-height);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

import React from "react";
import { useNavigate } from "react-router-dom";
import PATH from "Path";
import styled from "styled-components";
import Button from "components/Button";
import Img09 from "assets/reservation/img-09.jpg";

function AddGuestPage() {
  const navigate = useNavigate();

  return (
    <Container>
      <Content>
        <img src={Img09} alt="" />
      </Content>
      <Button
        label="예약하기"
        onClick={() =>
          navigate(`${PATH.reservation_complete}?mode=guest`, {
            replace: true,
          })
        }
      />
    </Container>
  );
}

export default AddGuestPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  max-height: calc(100% - 70px);
`;

const Content = styled.div`
  border-radius: 26px;
  overflow: hidden;

  .btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding-inline: 24px;
    box-sizing: border-box;
    width: 100%;
    height: 92px;
    background: #fff;
    overflow: auto hidden;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
`;

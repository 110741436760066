import React from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import PATH from "Path";
import styled from "styled-components";
import Button from "components/Button";
import Img16 from "assets/reservation/img-16.jpg";
import Img17 from "assets/reservation/img-17.jpg";
import Img18 from "assets/reservation/img-18.jpg";

function ReservationDetail() {
  const navigate = useNavigate();
  const [serchParams] = useSearchParams();
  const pageMode = serchParams.get("page") || "";

  const renderContent = () => {
    if (pageMode === "meeting_room") {
      return <img src={Img16} alt="" />;
    }
    if (pageMode === "sharing_place") {
      return <img src={Img17} alt="" />;
    }
    if (pageMode === "guest") {
      return <img src={Img18} alt="" />;
    }
  };

  return (
    <Container>
      <div className="scroll">{renderContent()}</div>
      <Button
        label="예약정보 변경"
        onClick={() => navigate(`${PATH.reservation}?list=${pageMode}`)}
      />
      <Button
        label="예약 취소"
        sort="secondary"
        onClick={() => navigate(`${PATH.reservation}?list=${pageMode}`)}
      />
    </Container>
  );
}

export default ReservationDetail;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  max-height: calc(100% - 70px);

  .scroll {
    width: 100%;
    height: fit-content;
    overflow: hidden scroll;
    border-radius: 26px;
  }

  img {
    display: block;
    width: 100%;
    height: fit-content;
    overflow: hidden;
  }
`;

import { useNavigate } from "react-router-dom";
import PATH from "Path";
import styled from "styled-components";
import Img05 from "assets/community/img-05.jpg";
import Button from "components/Button";

function FeedWritePage() {
  const navigate = useNavigate();
  return (
    <Container>
      <div className="content-wrapper">
        <img src={Img05} alt="" />
      </div>
      <Button
        label="완료"
        onClick={() => navigate(PATH.community_feed, { replace: true })}
      />
    </Container>
  );
}

export default FeedWritePage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  max-height: calc(100% - 70px);

  .content-wrapper {
    border-radius: 26px;
    overflow: hidden;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
`;

const PATH = {
  root: "/",
  //   splash: "splash",
  onboarding: "/onboarding",
  main: "/main",
  reservation: "/reservation",
  reservation_add: "/reservation/add",
  reservation_add_meeting_room: "/reservation/add/meeting_room",
  reservation_add_sharing_place: "/reservation/add/sharing_place",
  reservation_add_guest: "/reservation/add/guest",
  reservation_option: "/reservation/option",
  reservation_complete: "/reservation/complete",
  reservation_detail: "/reservation/detail",
  air_state: "/air_state",
  community: "/community",
  community_notice: "/community/notice",
  community_notice_detail: "/community/notice_detail",
  community_feed: "/community/feed",
  community_feed_detail: "/community/feed_detail",
  community_feed_write: "/community/feed_write",
  info: "/info",
  setting: "/setting",
  setting_detail: "detail",
  set_face: "/faceId",
};

export default PATH;

import { useNavigate } from "react-router-dom";
import PATH from "Path";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import styled from "styled-components";
import Button from "components/Button";
import Img01 from "assets/onboarding/img-01.jpg";
import Img02 from "assets/onboarding/img-02.jpg";
import Img03 from "assets/onboarding/img-03.jpg";
import Img04 from "assets/onboarding/img-04.jpg";
import Text01 from "assets/onboarding/text-01.png";
import Text02 from "assets/onboarding/text-02.png";
import Text03 from "assets/onboarding/text-03.png";
import Text04 from "assets/onboarding/text-04.png";

const assets = [
  {
    img: Img01,
    text: Text01,
  },
  {
    img: Img02,
    text: Text02,
  },
  {
    img: Img03,
    text: Text03,
  },
  {
    img: Img04,
    text: Text04,
  },
];

function OnboardingPage() {
  const navigate = useNavigate();
  return (
    <Container>
      <SwiperSection speed={450} pagination modules={[Pagination]}>
        {assets.map((item) => (
          <SwiperItem key={Math.random().toString()}>
            <img className="img" src={item.img} alt="" />
            <img className="text" src={item.text} alt="" />
          </SwiperItem>
        ))}
      </SwiperSection>
      <Button
        label="시작하기"
        onClick={() => navigate(PATH.root, { replace: true })}
        sort="primary"
      />
    </Container>
  );
}

export default OnboardingPage;

const Container = styled.div`
  margin-top: auto;
  width: 100%;
  height: 100dvh;
  padding-top: var(--top-nav-height);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const SwiperSection = styled(Swiper)`
  width: 100%;
  display: flex;
  flex: 1;
  border-radius: 26px;
  overflow: hidden;

  & .swiper-pagination {
    position: absolute;
    left: 50%;
    bottom: 79px;
    padding-block: 4px;
    box-sizing: border-box;
    transform: translate3d(-50%, 0, 0);
    display: flex;
    gap: 5px;
    z-index: 100;
  }
  & .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    background: #d7d7d7;
    border-radius: 3px;
    transition: width 600ms ease-in-out, background 400ms ease-in-out;
  }

  & .swiper-pagination-bullet-active {
    width: 13px;
    background: #1c77f9;
  }
`;

const SwiperItem = styled(SwiperSlide)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 80px;
  width: 100%;
  height: 100%;

  background: #fff;
  .img {
    display: block;
    width: 100%;
    height: 52.661%;
    object-fit: cover;
  }

  .text {
    width: 100%;
    padding-inline: 24px;
    box-sizing: border-box;
    object-fit: cover;
  }
`;

import styled, { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

const fontFamily =
  '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif';

const GlobalStyle = createGlobalStyle`
${reset};

:root {
  --top-nav-height: 32px;
}

html, body {
    width: 100%;
    background: #000;
    scroll-behavior: smooth;
    overflow-x: hidden;
}

#root {
    display: flex;
    font-family: ${fontFamily};
    width: 100%;
    min-height: 100%;
    background: #000;
}
`;

export default GlobalStyle;

import React, { useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import styled from "styled-components";
import TopNavigation from "components/TobNavigation";

function ReservationPage() {
  const { pathname } = useLocation();
  const [pageName, setPageName] = useState<string>("");

  useEffect(() => {
    const splitUrl = pathname.split("/");
    setPageName(splitUrl[splitUrl.length - 1]);
  }, [pathname]);

  const renderNavigation = () => {
    if (pageName === "add") {
      return <TopNavigation label="예약 추가" backType={-1} />;
    }
    if (pageName === "meeting_room") {
      return <TopNavigation label="회의실 예약" backType={-1} />;
    }
    if (pageName === "sharing_place") {
      return <TopNavigation label="공용공간 예약" backType={-1} />;
    }
    if (pageName === "guest") {
      return <TopNavigation label="게스트 예약" backType={-1} />;
    }
    if (pageName === "complete") {
      return <TopNavigation label="에약 완료" backType={-1} />;
    }
    if (pageName === "option") {
      return <></>;
    }
    if (pageName === "detail") {
      return <TopNavigation label="예약 상세 내역" backType={-1} />;
    }
    return <TopNavigation label="예약 내역" backType={".."} />;
  };
  return (
    <Container>
      {renderNavigation()}
      <Outlet />
    </Container>
  );
}

export default ReservationPage;

const Container = styled.div`
  margin-top: auto;
  width: 100%;
  height: 100dvh;
  padding-top: var(--top-nav-height);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

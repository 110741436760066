import React from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import PATH from "Path";
import styled from "styled-components";
import Button from "components/Button";
import Img06 from "assets/community/img-06.jpg";
import Img07 from "assets/community/img-07.jpg";
import Img08 from "assets/community/img-08.jpg";
import Img09 from "assets/community/img-09.jpg";
import Img10 from "assets/community/img-10.jpg";

function SettingDetailPage() {
  const navigate = useNavigate();
  const [serchParams] = useSearchParams();
  const optionType = serchParams.get("option") || "";

  const renderItem = () => {
    if (optionType === "userInfo") {
      return (
        <Content>
          <img src={Img06} alt="" />
        </Content>
      );
    }

    if (optionType === "facilityInfo") {
      return (
        <>
          <Content>
            <img src={Img07} alt="" />
          </Content>
          <Button label="입주 건물 변경" sort="tertiary" onClick={() => {}} />
        </>
      );
    }

    if (optionType === "faceId") {
      return (
        <>
          <Content>
            <img src={Img08} alt="" />
          </Content>
          <Button
            label="얼굴 정보 등록"
            onClick={() => navigate(PATH.set_face)}
          />
        </>
      );
    }
    if (optionType === "notification") {
      return (
        <Content>
          <img src={Img09} alt="" />
        </Content>
      );
    }
    if (optionType === "CS") {
      return (
        <Content>
          <img src={Img10} alt="" />
        </Content>
      );
    }
  };

  return <Container>{renderItem()}</Container>;
}

export default SettingDetailPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  max-height: calc(100% - 70px);
`;

const Content = styled.div`
  border-radius: 26px;
  overflow: hidden;
  img {
    display: block;
    width: 100%;
    height: auto;
  }
`;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PATH from "Path";
import styled from "styled-components";
import Button from "components/Button";
import { ReactComponent as Calendar } from "assets/common/calendar.svg";
import { ReactComponent as Time } from "assets/common/time.svg";
import { ReactComponent as Person } from "assets/common/person.svg";
import Img07 from "assets/reservation/img-07.jpg";

interface StyledProps {
  active: boolean;
}

function AddMeetingRoomPage() {
  const navigate = useNavigate();
  const [date, setDate] = useState("날짜");
  const [time, setTime] = useState("시작시간");
  const [person, setPerson] = useState("인원수");

  useEffect(() => {
    const getDate = sessionStorage.getItem("@option_date") || "날짜";
    getDate && setDate(getDate);

    const getTime = sessionStorage.getItem("@option_time") || "시작시간";
    getTime && setTime(getTime);

    const getPerson = sessionStorage.getItem("@option_person") || "인원수";
    getPerson && setPerson(getPerson);
  }, []);
  return (
    <Container>
      <Content>
        <div className="btn-wrapper">
          <TapButton
            active={date !== "날짜"}
            onClick={() =>
              navigate(`${PATH.reservation_option}?mode=date`, {
                replace: true,
              })
            }
          >
            <Calendar stroke={date !== "날짜" ? "#343A40" : "#ADB5BD"} />
            {date}
          </TapButton>
          <TapButton
            active={time !== "시작시간"}
            onClick={() =>
              navigate(`${PATH.reservation_option}?mode=time`, {
                replace: true,
              })
            }
          >
            <Time stroke={time !== "시작시간" ? "#343A40" : "#ADB5BD"} />
            {time}
          </TapButton>
          <TapButton
            active={person !== "인원수"}
            onClick={() =>
              navigate(`${PATH.reservation_option}?mode=person`, {
                replace: true,
              })
            }
          >
            <Person stroke={person !== "인원수" ? "#343A40" : "#ADB5BD"} />
            {person}
          </TapButton>
        </div>
        <div className="scroll">
          <img src={Img07} alt="" />
        </div>
      </Content>
      <Button
        label="예약하기"
        onClick={() =>
          navigate(`${PATH.reservation_complete}?mode=meeting_room`, {
            replace: true,
          })
        }
        disabled={
          !(date !== "날짜" && time !== "시작시간" && person !== "인원수")
        }
      />
    </Container>
  );
}

export default AddMeetingRoomPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  /* height: auto; */
  max-height: calc(100% - 70px);
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 26px;
  overflow: hidden;

  .btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding-inline: 24px;
    box-sizing: border-box;
    width: 100%;
    height: 92px;
    min-height: 92px;
    background: #fff;
    overflow: auto hidden;
  }

  .scroll {
    width: 100%;
    height: fit-content;
    overflow: hidden scroll;
  }

  img {
    display: block;
    width: 100%;
    /* height: auto; */
    height: fit-content;
  }
`;

const TapButton = styled.button<StyledProps>`
  display: flex;
  align-items: center;
  gap: 12px;
  height: 40px;
  padding: 8px 20px 8px 14px;
  box-sizing: border-box;
  border: initial;
  border-radius: 20px;
  color: ${({ active }) => (active ? "#343A40" : "#868e96")};
  background: #f1f3f5;
  white-space: nowrap;
`;

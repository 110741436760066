import { useNavigate } from "react-router-dom";
import PATH from "Path";
import styled from "styled-components";
import Img02 from "assets/community/img-02.jpg";
import Button from "components/Button";

function FeedPage() {
  const navigate = useNavigate();
  return (
    <Container>
      <div className="content-wrapper">
        <div className="btn-wrapper">
          <button
            className="btn01"
            onClick={() => navigate(PATH.community_notice)}
          >
            공지사항
          </button>
          <button className="btn02">피드</button>
        </div>
        <div className="scroll">
          <img
            src={Img02}
            onClick={() => navigate(PATH.community_feed_detail)}
            alt=""
          />
        </div>
      </div>
      <Button
        label="글쓰기"
        onClick={() => navigate(PATH.community_feed_write)}
      />
    </Container>
  );
}

export default FeedPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  max-height: calc(100% - 70px);

  .content-wrapper {
    border-radius: 26px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    padding-inline: 24px;
    box-sizing: border-box;
    width: 100%;
    height: 102px;
    min-height: 102px;
    background: #fff;
  }

  .btn01,
  .btn02 {
    width: 50%;
    height: 42px;
    border-radius: 21px;
    border: initial;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    transition: opacity 200ms ease-in-out;

    :active {
      opacity: 0.8;
    }
  }

  .btn02 {
    color: #fff;
    background: #1c77f9;
  }

  .btn01 {
    color: #1c77f9;
    background: #eef5ff;
  }

  .scroll {
    width: 100%;
    height: fit-content;
    overflow: hidden scroll;
  }

  img {
    display: block;
    width: 100%;
    height: fit-content;
  }
`;

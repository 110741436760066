import React, { useEffect, useState } from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import TopNavigation from "components/TobNavigation";

interface StyledProps {
  height: boolean;
}

function SettingPage() {
  const [serchParams] = useSearchParams();
  const optionType = serchParams.get("option") || "";

  const renderNavigation = () => {
    if (optionType === "userInfo") {
      return <TopNavigation label="회원정보" backType={-1} />;
    }

    if (optionType === "facilityInfo") {
      return <TopNavigation label="입주 건물 정보" backType={-1} />;
    }

    if (optionType === "faceId") {
      return <TopNavigation label="얼굴 정보 관리" backType={-1} />;
    }
    if (optionType === "notification") {
      return <TopNavigation label="푸시 알림 설정" backType={-1} />;
    }
    if (optionType === "CS") {
      return <TopNavigation label="고객 센터" backType={-1} />;
    }

    return <TopNavigation label="설정" backType={".."} />;
  };

  return (
    <Container height={!!optionType}>
      {renderNavigation()}
      <Outlet />
    </Container>
  );
}

export default SettingPage;

const Container = styled.div<StyledProps>`
  margin-top: auto;
  width: 100%;
  height: ${({ height }) => height && "100dvh"};
  overflow: hidden;
  padding-top: var(--top-nav-height);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

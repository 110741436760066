import React from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import PATH from "Path";
import styled from "styled-components";
import Button from "components/Button";
import Img13 from "assets/reservation/img-13.jpg";
import Img14 from "assets/reservation/img-14.jpg";
import Img15 from "assets/reservation/img-15.jpg";

function ReservationCompletePage() {
  const navigate = useNavigate();
  const [serchParams] = useSearchParams();
  const pageMode = serchParams.get("mode") || "";

  const renderContent = () => {
    if (pageMode === "meeting_room") {
      return <img src={Img13} alt="" />;
    }

    if (pageMode === "sharing_place") {
      return <img src={Img14} alt="" />;
    }

    if (pageMode === "guest") {
      return <img src={Img15} alt="" />;
    }
  };

  const submitHandler = () => {
    navigate(`${PATH.reservation}?list=${pageMode}`, { replace: true });
  };
  return (
    <Container>
      {renderContent()}
      <Button label="확인" onClick={submitHandler} />
    </Container>
  );
}

export default ReservationCompletePage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  max-height: calc(100% - 70px);

  img {
    width: 100%;
    height: auto;
    border-radius: 26px;
    object-fit: cover;
    overflow: hidden;
  }
`;

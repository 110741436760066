import React from "react";
import styled, { css } from "styled-components";

interface Props {
  label: string;
  onClick: () => void;
  sort?: "primary" | "secondary" | "tertiary";
  disabled?: boolean;
}

interface StyledProps {
  sort: "primary" | "secondary" | "tertiary";
}

function Button({ label, onClick, sort = "primary", disabled = false }: Props) {
  return (
    <Container onClick={onClick} sort={sort} disabled={disabled}>
      {label}
    </Container>
  );
}

export default Button;

const Container = styled.button.attrs({ type: "button" })<StyledProps>`
  width: 100%;
  height: 58px;
  min-height: 58px;
  border-radius: 26px;
  border: initial;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  user-select: none;
  transition: opacity 200ms ease-in-out;

  :active {
    opacity: 0.9;
  }

  ${({ sort }) => {
    switch (sort) {
      case "primary":
        return css`
          color: #fff;
          background: #1c77f9;
        `;
      case "secondary":
        return css`
          color: #ff3131;
          background: #fff;
        `;
      case "tertiary":
        return css`
          color: #000;
          background: #fff;
        `;
    }
  }};

  :disabled {
    background: #c1dbff;
  }
`;

import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import PATH from "Path";
import styled from "styled-components";
import Button from "components/Button";
import Img01 from "assets/reservation/img-01.jpg";
import Img02 from "assets/reservation/img-02.jpg";
import Img03 from "assets/reservation/img-03.jpg";

interface StyledProps {
  active: boolean;
}

function ReservationListPage() {
  const navigate = useNavigate();
  const [serchParams] = useSearchParams();
  const listType = serchParams.get("list") || "";
  useEffect(() => {
    if (listType === "") {
      navigate("?list=meeting_room");
    }
  }, [listType]);

  const renderContent = () => {
    if (listType === "meeting_room") {
      return (
        <img
          src={Img01}
          onClick={() =>
            navigate(`${PATH.reservation_detail}?page=${listType}`)
          }
          alt=""
        />
      );
    }
    if (listType === "sharing_place") {
      return (
        <img
          src={Img02}
          onClick={() =>
            navigate(`${PATH.reservation_detail}?page=${listType}`)
          }
          alt=""
        />
      );
    }
    if (listType === "guest") {
      return (
        <img
          src={Img03}
          onClick={() =>
            navigate(`${PATH.reservation_detail}?page=${listType}`)
          }
          alt=""
        />
      );
    }
  };
  return (
    <Container>
      <Content>
        <div className="btn-wrapper">
          <TapButton
            active={listType === "meeting_room"}
            className="btn"
            onClick={() => navigate("?list=meeting_room")}
          >
            회의실
          </TapButton>
          <TapButton
            active={listType === "sharing_place"}
            className="btn"
            onClick={() => navigate("?list=sharing_place")}
          >
            공용 공간
          </TapButton>
          <TapButton
            active={listType === "guest"}
            className="btn"
            onClick={() => navigate("?list=guest")}
          >
            게스트
          </TapButton>
        </div>
        {renderContent()}
      </Content>
      <Button
        label="예약 추가"
        onClick={() => navigate(PATH.reservation_add)}
      />
    </Container>
  );
}

export default ReservationListPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  max-height: calc(100% - 70px);
`;

const Content = styled.div`
  border-radius: 26px;
  overflow: hidden;

  .btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    padding-inline: 24px;
    box-sizing: border-box;
    width: 100%;
    height: 102px;
    background: #fff;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
`;

const TapButton = styled.button<StyledProps>`
  width: calc(100% / 3);
  height: 42px;
  border-radius: 21px;
  border: initial;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  transition: opacity, color, background 200ms ease-in-out;
  color: ${({ active }) => (active ? "#fff" : "#1c77f9;")};
  background: ${({ active }) => (active ? "#1C77F9" : "#eef5ff")};

  :active {
    opacity: 0.8;
  }
`;

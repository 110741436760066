import React from "react";
import { useNavigate, To } from "react-router-dom";
import styled from "styled-components";
import Img01 from "assets/common/top-nav-01.jpg";
import Img02 from "assets/common/top-nav-02.jpg";
import Img03 from "assets/common/top-nav-03.jpg";

interface Props {
  label: string;
  backType?: ".." | -1;
  more?: boolean;
  share?: boolean;
}

function TopNavigation({
  label,
  backType = -1,
  more = false,
  share = false,
}: Props) {
  const navigate = useNavigate();
  return (
    <Container>
      <LeftItem onClick={() => navigate(backType as To)}>
        <img src={Img01} alt="" />
      </LeftItem>
      <span className="label">{label}</span>
      <RightItem>
        {share && <img src={Img02} alt="" />}
        {more && <img src={Img03} alt="" />}
      </RightItem>
    </Container>
  );
}

export default TopNavigation;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 70px;
  min-height: 70px;
  background: transparent;

  .label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    color: #fff;
    font-size: 24px;
    font-weight: 400;
    line-height: 25px;
  }
`;

const LeftItem = styled.div`
  position: absolute;
  top: 50%;
  left: 13px;
  width: 44px;
  height: 44px;
  transform: translate3d(0, -50%, 0);
  border-radius: 22px;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const RightItem = styled.div`
  position: absolute;
  top: 50%;
  right: 13px;
  transform: translate3d(0, -50%, 0);
  display: flex;
  gap: 12px;

  img {
    display: block;
    width: 44px;
    height: 44px;
    object-fit: cover;
    border-radius: 22px;
    overflow: hidden;
  }
`;

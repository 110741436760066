import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import PATH from "Path";

function Layout() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    navigate(PATH.onboarding, {
      replace: false,
    });
  }, []);
  return <Outlet />;
}

export default Layout;

import React from "react";
import { useNavigate } from "react-router-dom";
import PATH from "Path";
import styled from "styled-components";
import Button from "components/Button";
import { ReactComponent as Calendar } from "assets/common/calendar.svg";
import { ReactComponent as Time } from "assets/common/time.svg";
import { ReactComponent as Person } from "assets/common/person.svg";
import Img08 from "assets/reservation/img-08.jpg";

function AddSharingPlacePage() {
  const navigate = useNavigate();

  return (
    <Container>
      <Content>
        <div className="btn-wrapper">
          <TapButton>
            <Calendar stroke="#343A40" />
            {"10월 13일"}
          </TapButton>
          <TapButton>
            <Time stroke="#343A40" />
            {"15:30"}
          </TapButton>
          <TapButton>
            <Person stroke="#343A40" />
            {"1"}
          </TapButton>
        </div>
        <img src={Img08} alt="" />
      </Content>
      <Button
        label="예약하기"
        onClick={() =>
          navigate(`${PATH.reservation_complete}?mode=sharing_place`, {
            replace: true,
          })
        }
      />
    </Container>
  );
}

export default AddSharingPlacePage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  max-height: calc(100% - 70px);
`;

const Content = styled.div`
  border-radius: 26px;
  overflow: hidden;

  .btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding-inline: 24px;
    box-sizing: border-box;
    width: 100%;
    height: 92px;
    background: #fff;
    overflow: auto hidden;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
    margin-top: -20px;
  }
`;

const TapButton = styled.button`
  display: flex;
  align-items: center;
  gap: 12px;
  height: 40px;
  padding: 8px 20px 8px 14px;
  box-sizing: border-box;
  border: initial;
  border-radius: 20px;
  color: "#343A40";
  background: #f1f3f5;
  white-space: nowrap;
`;

import styled from "styled-components";
import Img03 from "assets/community/img-03.jpg";

function NoticeDetailPage() {
  return (
    <Container>
      <img src={Img03} alt="" />
    </Container>
  );
}

export default NoticeDetailPage;

const Container = styled.div`
  width: 100%;
  height: auto;
  border-radius: 26px;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
  }
`;

import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import PATH from "Path";
import Webcam from "react-webcam";
import styled from "styled-components";
import TopNavigation from "components/TobNavigation";
import Button from "components/Button";
import Filter from "assets/face/filter.png";
import Img01 from "assets/face/img-01.png";
import Img02 from "assets/face/img-02.png";

const videoConstraints = {
  facingMode: "user",
  audio: false,
};

interface StyledProps {
  active: boolean;
}

function FaceId() {
  const navigate = useNavigate();
  const webcamRef = useRef<Webcam>(null);
  const [url, setUrl] = useState<string | null>(null);
  const [ready, setReady] = useState<boolean>(false);

  const capture = () => {
    const imageSrc = webcamRef.current?.getScreenshot();
    if (imageSrc) {
      setUrl(imageSrc);
      setTimeout(() => navigate(PATH.root), 2000);
    }
  };

  return (
    <Container>
      <TopNavigation label="얼굴 정보 등록" backType={-1} />
      <Content active={ready}>
        <div className="webcam">
          <img className="filter" src={Filter} alt="" />
          <Webcam
            width={"100%"}
            ref={webcamRef}
            audio={false}
            videoConstraints={videoConstraints}
            onUserMedia={() => setReady(true)}
          />
          {url && <img className="img" src={url} alt="Screenshot" />}
        </div>
        <Info active={!!url} className="info">
          {!url ? <img src={Img01} alt="" /> : <img src={Img02} alt="" />}
        </Info>
        {!url && <Button label="촬영" onClick={capture} />}
      </Content>
    </Container>
  );
}

export default FaceId;

const Container = styled.div`
  position: relative;
  margin-top: auto;
  width: 100%;
  height: 100dvh;
  overflow: hidden;
  padding-top: var(--top-nav-height);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div<StyledProps>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
  max-height: calc(100% - 70px);

  .webcam {
    position: relative;
    flex: 1;
    border-radius: 26px;
    overflow: hidden;
    background: #fff;
  }

  .webcam video {
    display: block;
    width: 100%;
    height: auto;
  }

  .filter {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 50;
    opacity: ${({ active }) => (active ? 1 : 0)};
  }

  .img {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 100;
  }
`;

const Info = styled.div<StyledProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: pre-line;
  height: ${({ active }) => (active ? "226px" : "164px")};
  border-radius: 26px;
  overflow: hidden;
  background: #fff;

  img {
    display: block;
    width: 312px;
  }
`;

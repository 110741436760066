import React from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import PATH from "Path";
import styled from "styled-components";
import Button from "components/Button";
import Img10 from "assets/reservation/img-10.jpg";
import Img11 from "assets/reservation/img-11.jpg";
import Img12 from "assets/reservation/img-12.jpg";

function ReservationOptionPage() {
  const navigate = useNavigate();
  const [serchParams] = useSearchParams();
  const optionMode = serchParams.get("mode") || "";

  const renderContent = () => {
    if (optionMode === "date") {
      return <img src={Img10} alt="" />;
    }
    if (optionMode === "time") {
      return <img src={Img11} alt="" />;
    }
    if (optionMode === "person") {
      return <img src={Img12} alt="" />;
    }
  };

  const submitHandler = () => {
    if (optionMode === "date") {
      sessionStorage.setItem("@option_date", "10월 15일");
    }
    if (optionMode === "time") {
      sessionStorage.setItem("@option_time", "12:18");
    }
    if (optionMode === "person") {
      sessionStorage.setItem("@option_person", "4");
    }

    navigate(PATH.reservation_add_meeting_room);
  };

  return (
    <Container>
      {renderContent()}
      <Button label="선택완료" onClick={submitHandler} />
    </Container>
  );
}

export default ReservationOptionPage;

const Container = styled.div`
  position: absolute;
  bottom: 0;
  margin-top: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;

  img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 26px;
    overflow: hidden;
  }
`;

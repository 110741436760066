import React from "react";
import { useNavigate } from "react-router-dom";
import PATH from "Path";
import styled from "styled-components";
import Img01 from "assets/main/img-01.jpg";
import Img02 from "assets/main/img-02.jpg";
import Img03 from "assets/main/img-03.jpg";
import Img04 from "assets/main/img-04.jpg";
import Img05 from "assets/main/img-05.jpg";
import Img06 from "assets/main/img-06.jpg";
import Img07 from "assets/main/img-07.png";

function MainPage() {
  const navigate = useNavigate();
  return (
    <Container>
      <div className="main" onClick={() => navigate(PATH.setting)}>
        <img src={Img01} alt="" />
      </div>
      <div className="reservation" onClick={() => navigate(PATH.reservation)}>
        <img src={Img02} alt="" />
      </div>
      <div
        className="community"
        onClick={() => navigate(PATH.community_notice)}
      >
        <img src={Img03} alt="" />
      </div>
      <div className="air-state" onClick={() => navigate(PATH.air_state)}>
        <img src={Img04} alt="" />
      </div>
      <div className="inline">
        <div className="mode" onClick={() => navigate(PATH.info)}>
          <img src={Img05} alt="" />
        </div>
        <div className="report">
          <img src={Img06} alt="" />
        </div>
      </div>
      <div className="footer">
        <img src={Img07} alt="" />
      </div>
    </Container>
  );
}

export default MainPage;

const Container = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 4px;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
    -webkit-user-drag: none;
  }

  .main {
    width: 100%;
    height: auto;
    border-radius: 0 0 26px 26px;
    overflow: hidden;

    img {
      margin-bottom: -5px;
    }
  }

  .reservation {
    width: 100%;
    height: auto;
    border-radius: 26px;
    overflow: hidden;
    background: #fff;
  }

  .community {
    width: 100%;
    height: auto;
    border-radius: 26px;
    overflow: hidden;
    background: #fff;
  }

  .air-state {
    width: 100%;
    height: auto;
    border-radius: 26px;
    overflow: hidden;
    background: #fff;
  }

  .inline {
    display: flex;
    gap: 4px;
  }

  .mode,
  .report {
    position: relative;
    width: 50%;
    padding-bottom: 50%;
    border-radius: 26px;
    overflow: hidden;
    background: #fff;

    img {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .footer {
    margin-top: 36px;
    width: 100%;
    height: auto;
  }
`;

import React from "react";
import { useNavigate } from "react-router-dom";
import PATH from "Path";
import styled from "styled-components";
import Img04 from "assets/reservation/img-04.jpg";
import Img05 from "assets/reservation/img-05.jpg";
import Img06 from "assets/reservation/img-06.jpg";

function ReservationAddPage() {
  const navigate = useNavigate();
  return (
    <Container>
      <img
        src={Img04}
        onClick={() => navigate(PATH.reservation_add_meeting_room)}
        alt=""
      />
      <img
        src={Img05}
        onClick={() => navigate(PATH.reservation_add_sharing_place)}
        alt=""
      />
      <img
        src={Img06}
        onClick={() => navigate(PATH.reservation_add_guest)}
        alt=""
      />
    </Container>
  );
}

export default ReservationAddPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  height: auto;

  img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 26px;
    overflow: hidden;
  }
`;

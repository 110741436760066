import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import PATH from "Path";
import Layout from "Layout";
import OnboardingPage from "pages/onboarding/Onboarding";
import MainPage from "pages/main/Main";
import InfoPage from "pages/info/Info";
import AirStatePage from "pages/air_state/AirState";
import CommunityPage from "pages/community/Community";
import NoticePage from "pages/community/Notice";
import NoticeDetailPage from "pages/community/NoticeDetail";
import FeedPage from "pages/community/Feed";
import FeedDetailPage from "pages/community/FeedDetail";
import FeedWritePage from "pages/community/FeedWrite";
import SettingPage from "pages/setting/Setting";
import SettingIndexPage from "pages/setting/SettingIndex";
import SettingDetailPage from "pages/setting/SettingDetail";
import FaceId from "pages/setting/FaceId";
import ReservationPage from "pages/reservation/Reservation";
import ReservationListPage from "pages/reservation/ReservationList";
import ReservationAddPage from "pages/reservation/ReservationAdd";
import AddMeetingRoomPage from "pages/reservation/AddMeetingRoom";
import AddSharingPlacePage from "pages/reservation/AddSharingPlace";
import AddGuestPage from "pages/reservation/AddGuest";
import ReservationOptionPage from "pages/reservation/ReservationOption";
import ReservationCompletePage from "pages/reservation/ReservationComplete";
import ReservationDetail from "pages/reservation/ReservationDetail";

const router = createBrowserRouter([
  {
    path: PATH.root,
    element: <Layout />,
    children: [
      {
        path: PATH.onboarding,
        element: <OnboardingPage />,
      },
      {
        index: true,
        // path: PATH.main,
        element: <MainPage />,
      },
      {
        path: PATH.air_state,
        element: <AirStatePage />,
      },
      {
        path: PATH.reservation,
        element: <ReservationPage />,
        children: [
          {
            index: true,
            element: <ReservationListPage />,
          },
          {
            path: PATH.reservation_add,
            element: <ReservationAddPage />,
          },
          {
            path: PATH.reservation_add_meeting_room,
            element: <AddMeetingRoomPage />,
          },
          {
            path: PATH.reservation_add_sharing_place,
            element: <AddSharingPlacePage />,
          },
          {
            path: PATH.reservation_add_guest,
            element: <AddGuestPage />,
          },
          {
            path: PATH.reservation_option,
            element: <ReservationOptionPage />,
          },
          {
            path: PATH.reservation_complete,
            element: <ReservationCompletePage />,
          },
          {
            path: PATH.reservation_detail,
            element: <ReservationDetail />,
          },
        ],
      },
      {
        path: PATH.info,
        element: <InfoPage />,
      },
      {
        path: PATH.community,
        element: <CommunityPage />,
        children: [
          {
            index: true,
            path: PATH.community_notice,
            element: <NoticePage />,
          },
          {
            index: true,
            path: PATH.community_notice_detail,
            element: <NoticeDetailPage />,
          },
          {
            path: PATH.community_feed,
            element: <FeedPage />,
          },
          {
            path: PATH.community_feed_detail,
            element: <FeedDetailPage />,
          },
          {
            path: PATH.community_feed_write,
            element: <FeedWritePage />,
          },
        ],
      },
      {
        path: PATH.setting,
        element: <SettingPage />,
        children: [
          {
            index: true,
            element: <SettingIndexPage />,
          },
          {
            path: PATH.setting_detail,
            element: <SettingDetailPage />,
          },
        ],
      },
      {
        path: PATH.set_face,
        element: <FaceId />,
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
